<div class="p-24" fxLayout="column" fxLayoutAlign="start stretch" style="min-height: 100%;">

    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div mat-dialog-title fxLayoutAlign="space-between center">
        <div fxLayout="row wrap" fxLayoutAlign="start center">
            <div class="contact" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="s-24 mr-10">mail</mat-icon>
                <span class="h2">Contact Us</span>
            </div>
        </div>
        <div>
            <button mat-icon-button (click)="onNoClick()" aria-label="Close">
                <mat-icon class="s-18" matTooltip="close">close</mat-icon>
            </button>
        </div>
    </div>

    <mat-dialog-content simplebar>
        <form [formGroup]="form" fxLayout="column" fxLayoutAlign="start stretch">
<!--            <mat-radio-group formControlName="type" aria-label="Select reason for contact" labelPosition="before">-->
<!--                <div class="mb-16" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px">-->
<!--                    <mat-radio-button value="problem" aria-label="Report Issue">Report Issue</mat-radio-button>-->
<!--                    <mat-radio-button value="question" aria-label="Ask Question">Ask Question</mat-radio-button>-->
<!--                </div>-->
<!--            </mat-radio-group>-->

            <mat-form-field>
                <mat-label>To</mat-label>
                <input matInput
                       formControlName="to"
                       type="text"
                       autocomplete="off"
                       aria-label="To">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Name</mat-label>
                <input matInput
                       formControlName="name"
                       type="text"
                       required
                       autocomplete="off"
                       aria-label="Name"
                       placeholder="Provide your name">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput
                       formControlName="email"
                       type="text"
                       required
                       autocomplete="off"
                       aria-label="Email"
                       placeholder="Provide your email">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Organization</mat-label>
                <input matInput
                       formControlName="organization"
                       type="text"
                       required
                       autocomplete="off"
                       aria-label="Organization"
                       placeholder="Provide your organization name">
            </mat-form-field>

            <mat-form-field>
                <mat-label>URL</mat-label>
                <input matInput
                       formControlName="url"
                       type="text"
                       autocomplete="off"
                       aria-label="URL"
                       placeholder="Attach the URL related to the
                        {{form.get('type').value === 'problem' ? 'issue' : 'question'}} if applicable">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Subject</mat-label>
                <input matInput
                       formControlName="subject"
                       type="text"
                       required
                       autocomplete="off"
                       aria-label="Subject"
                       placeholder="Provide a subject for this {{form.get('type').value === 'problem' ? 'issue' : 'question'}}">
            </mat-form-field>

            <mat-form-field>
                <textarea formControlName="description" matInput
                          required
                          maxlength="1000"
                          class="mt-24 mat-body-1"
                          [cdkTextareaAutosize] ="true"
                          [cdkAutosizeMinRows]="3"
                          [cdkAutosizeMaxRows]="5"
                          placeholder="Please provide a brief description of the {{form.get('type').value === 'problem'
                            ? 'issue' : 'question'}}">
                </textarea>
            </mat-form-field>

            <div class="send" fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div>
                    <a href="https://studentoc.zendesk.com/hc/en-us/categories/5182669360147-Projects-Portal" target="_blank">
                        Help Center
                    </a>
                </div>
                <button (click)="onSubmit()" mat-raised-button color="primary" [disabled]="loading"
                        aria-label="Submit">
                    <span>Send</span>
                </button>
            </div>
        </form>
    </mat-dialog-content>
</div>
